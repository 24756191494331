import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1920px;
`;

const Offer6 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "ig-kwas-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 550, quality: 100, formats: [WEBP])
        }
      }
      two: file(relativePath: { eq: "ig-kwas-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 550, quality: 100, formats: [WEBP])
        }
      }
      three: file(relativePath: { eq: "ig-kwas-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 550, quality: 100, formats: [WEBP])
        }
      }
      four: file(relativePath: { eq: "ig-kwas-4.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 550, quality: 100, formats: [WEBP])
        }
      }
      five: file(relativePath: { eq: "ig-kwas-5.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 550, quality: 100, formats: [WEBP])
        }
      }
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.wypelnianieZmarszczek}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Wypełnianie zmarszczek kwasem hialuronowym
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="wypełnienie zmarszczek kwasem hialuronowym ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>

            <Subtitle>
              Wskazania do zabiegu wypełniania zmarszczek kwasem hialuronowym
            </Subtitle>
            <Paragraph>
              Wraz z wiekiem zmniejsza się ilość oraz jakość kwasu hialuronowego
              występującego w skórze, co prowadzi do jej zwiotczenia oraz
              pojawienia się zmarszczek. Preparaty zawierające kwas hialuronowy
              wstrzyknięte do skóry nawilżają ją, wypełniają zmarszczki i
              pobudzają naturalne procesy regeneracyjne, dzięki czemu nawet po
              całkowitym wchłonięciu preparatu skóra wygląda lepiej niż przed
              zabiegiem.
            </Paragraph>
            <Paragraph noMarginBottom>
              Kwas hialuronowy ma zastosowanie w wypełnianiu zmarszczek w
              następujących okolicach:
            </Paragraph>
            <List>
              <ListItem>Bruzdy nosowo-wargowe</ListItem>
              <ListItem>Linie marionetki</ListItem>
              <ListItem>Zmarszczki wokół ust (“Zmarszczki palacza”)</ListItem>
              <ListItem>„Kurze łapki”</ListItem>
              <ListItem>
                Inne drobne zmarszczki linijne w dolnej części twarzy
              </ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Opis zabiegu</Subtitle>
            <Paragraph>
              Zabieg <b>wypełnienia zmarszczek kwasem hialuronowym</b> zawsze
              poprzedzony jest indywidualną konsultacją lekarską. Polega on na
              podaniu bardzo cienką igłą lub kaniulą (bardzo cienka rureczka bez
              ostrego zakończenia) kwasu hialuronowego, który wypełni powstałe z
              wiekiem bruzdy nadając twarzy młodszego wyglądu.
            </Paragraph>
            <Paragraph>
              Zabieg wykonuje się w miejscu wcześniej znieczulonym przy pomocy
              specjalnego kremu lub preparatem zawierającym znieczulenie.
            </Paragraph>
            <Subtitle>
              Po jakim czasie i jak długo widoczne są efekty zabiegu wypełniania
              zmarszczek kwasem hialuronowym?
            </Subtitle>
            <Paragraph>
              Efekty zabiegu widoczne są natychmiast. W zależności od preparatu
              efekty mogą się utrzymywać od 6 do 12 miesięcy, następnie preparat
              zostaje naturalnie zmetabolizowany przez organizm. Po tym czasie
              zabieg należy powtórzyć. Zdjęcia ukazują efekt zabiegu u
              konkretnego pacjenta. Efekt zabiegu może się różnić w zależności
              od indywidualnych cech pacjenta, liczby powtórzeń zabiegu,
              stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności
              i doświadczenia osoby przeprowadzającej zabieg.
            </Paragraph>
            <Subtitle>
              Zdjęcia przed po zabiegu wypełniania zmarszczek kwasem
              hialuronowym
            </Subtitle>
            <ImageWrapper>
              <Image
                src={images.one.childImageSharp}
                alt="bruzdy nosowo-wargowe wypełnienie kwasem hialuronowym przed po ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.two.childImageSharp}
                alt="bruzdy nosowo-wargowe wypełnienie kwasem hialuronowym przed po"
              />
              <Image
                src={images.three.childImageSharp}
                alt="bruzdy nosowo-wargowe wypełnienie kwasem hialuronowym przed po ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.four.childImageSharp}
                alt="bruzdy nosowo-wargowe wypełnienie kwasem hialuronowym przed po ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.five.childImageSharp}
                alt=" wypełnienie kwasem hialuronowym przed po ideale medycyna estetyczna warszawa wola"
              />
            </ImageWrapper>
          </Section>
          <Section>
            <Subtitle>
              Cena zabiegu wypełniania zmarszczek kwasem hialuronowym
            </Subtitle>
            <Paragraph>
              Cena zabiegu wypełnienia zmarszczek kwasem hialuronowym zaczyna
              się już od 900 zł w zależności od ilości zużytego preparatu.
              Zapraszamy do rezerwacji. Przy pierwszej wizycie odbierz swoją
              kartę lojalnościową i otrzymaj -30% na czwarty zabieg medycyny
              estetycznej wykonany w Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>
              Przeciwwskazania do wypełniania zmarszczek kwasem hialuronowym
            </Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>Skłonność do powstawania blizn przerostowych</ListItem>
              <ListItem>Nadwrażliwość na kwas hialuronowy</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Czym jest kwas hialuronowy?</Subtitle>
            <Paragraph>
              Kwas hialuronowy to związek chemiczny z grupy glikozaminoglikanów,
              który występuje naturalnie w organizmach żywych, w tym w ludzkim
              ciele. Jest to substancja o żelowej konsystencji, która pełni
              ważną rolę w utrzymaniu odpowiedniego nawilżenia i elastyczności
              skóry, a także w procesach regeneracji tkanek.
            </Paragraph>
            <Paragraph>
              Kwas hialuronowy jest składnikiem istotnym dla zdrowej skóry,
              stawów i oczu. W skórze występuje głównie w warstwie skóry
              właściwej, gdzie jest odpowiedzialny za utrzymanie odpowiedniego
              poziomu nawilżenia. Posiada zdolność wiązania i zatrzymywania
              dużej ilości wody, co przyczynia się do utrzymania jędrności,
              elastyczności i gładkości skóry.
            </Paragraph>
            <Paragraph>
              Wraz z upływem czasu, produkcja naturalnego kwasu hialuronowego w
              organizmie zmniejsza się, co może prowadzić do utraty nawilżenia i
              powstawania zmarszczek. W związku z tym, kwas hialuronowy jest
              szeroko wykorzystywany w medycynie estetycznej. Może być stosowany
              w postaci zastrzyków lub jako składnik kosmetyków, takich jak
              kremy, serum czy maski, w celu nawilżenia skóry, redukcji
              zmarszczek i poprawy ogólnego wyglądu skóry.
            </Paragraph>
            <Paragraph>
              Ponadto, <b>kwas hialuronowy</b> może być stosowany w medycynie,
              na przykład w leczeniu chorób stawów, takich jak osteoartroza,
              gdzie wstrzykiwany jest bezpośrednio do stawu w celu zmniejszenia
              bólu i poprawy funkcji stawu.
            </Paragraph>
            <Subtitle>Kwas hialuronowy w medycynie estetycznej</Subtitle>
            <Paragraph>
              Kwas hialuronowy jest jednym z najpopularniejszych składników
              stosowanych w medycynie estetycznej. Ma wiele zastosowań w
              poprawianiu wyglądu skóry i przeciwdziałaniu procesom starzenia.
              Jednym z głównych zastosowań kwasu hialuronowego w medycynie
              estetycznej jest redukcja zmarszczek. Kiedy kwas hialuronowy jest
              wstrzykiwany pod skórę, wypełnia ubytki i zmarszczki, nadając
              skórze gładki i bardziej młodzieńczy wygląd. Może być stosowany na
              różnych obszarach twarzy, takich jak linie wokół ust (tzw.
              zmarszczki palacza), bruzdy nosowo-wargowe, linie marionetki. Kwas
              hialuronowy może również być stosowany do konturowania i
              modelowania twarzy. Może pomóc w podkreśleniu kości policzkowych,
              kształtowaniu brody, podniesieniu opadających kącików ust czy
              wygładzeniu podbródka. Wstrzyknięcie kwasu hialuronowego w
              odpowiednie miejsca pozwala na tworzenie subtelnych zmian w
              proporcjach twarzy i poprawę jej symetrii.
            </Paragraph>
            <Paragraph>
              Innym zastosowaniem kwasu hialuronowego jest poprawa objętości i
              nawilżenia skóry. Może być stosowany do wypełnienia ubytków,
              takich jak blizny, zagłębienia czy asymetrie. Dodatkowo, może
              pomóc w poprawie elastyczności i jędrności skóry, przynosząc efekt
              odmłodzenia.
            </Paragraph>
            <Paragraph>
              Procedury związane z kwasem hialuronowym w medycynie estetycznej
              są zwykle stosunkowo bezpieczne i dają natychmiastowe rezultaty.
              Efekty utrzymują się przez okres od kilku miesięcy do nawet dwóch
              lat, w zależności od typu kwasu hialuronowego, miejsca
              wstrzyknięcia oraz indywidualnej reakcji organizmu.
            </Paragraph>
            <Paragraph>
              Należy pamiętać, że procedury związane z kwasem hialuronowym
              powinny być wykonywane przez doświadczonego lekarza medycyny
              estetycznej,który ma wiedzę i umiejętności w zakresie bezpiecznego
              i skutecznego stosowania kwasu hialuronowego.
            </Paragraph>
            <Subtitle>Czym są bruzdy nosowo-wargowe?</Subtitle>
            <Paragraph>
              Bruzdy nosowo-wargowe to załamania skóry, które się tworzą na
              miejscu łączenia górnej wargi i policzka, zaczynają się przy
              skrzydełku nosa i sięgają bocznie od kącika ust. Zmarszczki te
              wynikają z mimiki (artykulacja i śmiech) i zmniejszenia
              sprężystości skóry i objętości tkanek (opadanie policzków). Często
              bruzdy nosowo-wargowe pogłębiają się gdy śpimy na boku lub
              schudniemy.
            </Paragraph>
            <Subtitle>Czym są linie marionetek?</Subtitle>
            <Paragraph>
              Linie marionetek inaczej zmarszczki marionetek to załamania skóry,
              które biegną od kącika ust w dół. Zmarszczki te wynikają z pracy
              mięśni mimicznych (usta w podkówkę) i zmniejszenia sprężystości
              skóry i objętości tkanek (opadanie policzków). Zmarszczki te
              nadają naszej twarzy smutny wygląd.
            </Paragraph>
            <Subtitle>Czym są “zmarszczki palacza”?</Subtitle>
            <Paragraph>
              Zmarszczki palacza, inaczej “kod kreskowy” to drobne zmarszczki
              promieniście układające się wokół ust, szczególnie nad górną
              wargą. Powstają na skutek nasilonej pracy mięśnia okrężnego ust.
              Często obecne u palaczy ze względu na charakterystyczny “dziubek”
              podczas palenia papierosa ale nie tylko. Występują również u
              pacjentów, którzy nigdy nie palili, a mają dużą aktywność mięśni
              wokół ust.
            </Paragraph>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              <b>
                IDEALE Medycyna Estetyczna to gabinet stworzony przez kobietę
                dla kobiet.
              </b>{' '}
              Dążymy do tego aby nasi Pacjenci poczuli się piękni i dostrzegli w
              sobie wyjątkowe, unikatowe cechy. Podkreślamy to co interesujące.
              Słuchamy i doradzamy. Nasz doświadczony lekarz medycyny
              estetycznej ułoży dla Ciebie plan, indywidualnie dopasuje zabiegi,
              doradzi w temacie pielęgnacji skóry, dzięki czemu wyjdziesz od nas
              piękna, naturalna i promienna, a Twoja skóra będzie wyglądać na
              młodszą i bardziej wypoczętą. Nasza klinika mieści się w Warszawie
              na Woli, tuż przy stacji metra Młynów zapewniając łatwy dojazd
              naszym pacjentom. Wokół kliniki znajduje się również wiele miejsc
              parkingowych.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu skutecznych i bezpiecznych
              zabiegów medycyny estetycznej, które czerpiemy prosto z
              międzynarodowych kongresów i szkoleń. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i swoją unikatowość.
              Poczujesz się zadbana i zrelaksowana. Zaufaj naszemu
              doświadczeniu, abyś mogła cieszyć się piękną, zadbaną i młodą
              skórą.
            </Paragraph>
            <Paragraph>
              IDEALE, Gabinet Medycyny Estetycznej, ul. Sokołowska 9, Wola,
              Warszawa.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer6;

export const Head = () => <SEO tags={TAGS.wypelnianieZmarszczek} />;
